import request from '@/utils/request';

export function createMain(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      if(key == 'data'){
        Object.keys(data[key]).forEach(chKey => {
          formData.append(`${key}[${chKey}]`, data[key][chKey]);
        })
      }else{
        formData.append(key, data[key]);
      }
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/applications/main',
    method: 'post',
    data: formData
  });
}

function recursiveCheckObj(parentKey, value, formData){
  if(typeof value == 'object'){
    Object.keys(value).forEach(key => {
      recursiveCheckObj(parentKey+`[${key}]`, value[key], formData);
    })
  }else{
    formData.append(parentKey, value);
  }
}

export function editMain(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      if(key == 'data'){
        Object.keys(data[key]).forEach(chKey => {
          if(data[key][chKey]){
            formData.append(`${key}[${chKey}]`, data[key][chKey]);
          }
        })
      }else{
        if(data[key]){
          formData.append(key, data[key]);
        }
      }
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/applications/main/'+data['id'],
    method: 'post',
    data: formData
  });
}

export function createHackathon(data) {
  return request({
    url: '/applications/hackathon',
    method: 'post',
    data
  });
}

export function editHackathon(data) {
  return request({
    url: '/applications/hackathon/'+data['id'],
    method: 'put',
    data
  });
}

export function list(params) {
  return request({
    url: '/applications',
    method: 'get',
    params
  });
}

export function showById(id) {
  return request({
    url: '/applications/'+id,
    method: 'get'
  });
}

export function confirm(id) {
  return request({
    url: '/applications/'+id+'/confirm',
    method: 'put'
  });
}

export function revise(data) {
  return request({
    url: '/applications/'+data['id']+'/revise',
    method: 'put',
    data
  });
}

export function getUnreadCount(){
  return request({
    url: '/statistics/count/applications',
    method: 'get'
  });
}

export function updateColumnStatus(data){
  return request({
    url: `/applications/${data['id']}/update-column-status`,
    method: 'put',
    data
  });
}

export function getHackathonStartDate(){
  return request({
    url: '/hackathon-start-date',
    method: 'get'
  });
}

export function hackathonProject(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      if(key == 'data'){
        Object.keys(data[key]).forEach(chKey => {
          if(data[key][chKey]){
            formData.append(`${key}[${chKey}]`, data[key][chKey]);
          }
        })
      }else{
        if(data[key]){
          formData.append(key, data[key]);
        }
      }
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/applications/hackathon-project/'+data['id'],
    method: 'post',
    data: formData
  });
}

export function excelExport(params) {
  return request({
    url: '/applications/excel-export',
    method: 'get',
    params
  });
}

export function sendSms(params) {
  return request({
    url: '/applications/send-sms',
    method: 'post',
    params
  });
}