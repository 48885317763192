import request from '@/utils/request';

export function regions(params) {
  return request({
    url: '/regions',
    method: 'get',
    params
  });
}

export function cities(params) {
  return request({
    url: '/cities',
    method: 'get',
    params
  });
}

export function tasks(params) {
  return request({
    url: '/tasks',
    method: 'get',
    params
  });
}

export function categories(params) {
  return request({
    url: '/categories',
    method: 'get',
    params
  });
}

export function countries(params) {
  return request({
    url: '/countries',
    method: 'get',
    params
  });
}

export function directions(params) {
  return request({
    url: '/directions',
    method: 'get',
    params
  });
}

export function getAll(params) {
  return request({
    url: '/get-classifiers',
    method: 'get',
    params
  });
}