import request from '@/utils/request';

export function getFirst() {
  return request({
    url: '/notifications/get-first',
    method: 'get'
  });
}

export function getList() {
  return request({
    url: '/notifications/list',
    method: 'get'
  });
}