<template>
  <el-input type="tel" key="phone" v-model="model" v-mask="'+998 (##) ### ## ##'" placeholder="+998 (__) ___ __ __"/>
</template>

<script>

export default {
  props:{
    value: {
      type: String
    },
  },
  data(){
    return {
      model: ''
    }
  },
  watch: {
    model(value){
      if (value && value.length) {
        this.$emit('input', '+'+String(value).replace(/\D/g, ''));
      } else {
        this.$emit('input', '');
      }
    }
  },
  mounted(){
    this.model = this.value?this.value:"";
  },
  computed: {}
}
</script>
