import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth';
import loader from './loader';
import news from './news';
import classifiers from './classifiers';
import member from './member';
import teams from './teams';
import notifications from './notifications';
import applications from './applications';
import gallery from './gallery';
import users from './users';
import votation from './votation';
import statistics from './statistics'
import checklist from './checklist'
import juryVotation from './juryVotation'
import teamChangeRequests from './teamChangeRequests'
import app from './app'

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    news,
    classifiers,
    member,
    teams,
    notifications,
    applications,
    gallery,
    users,
    votation,
    statistics,
    checklist,
    juryVotation,
    teamChangeRequests,
    app
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy['classifiers'] = storeData['classifiers'];
  initialStateCopy['auth']['is_auth'] = false;
  initialStateCopy['auth']['user'] = null;
  initialStateCopy['auth']['role_name'] = "";
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}