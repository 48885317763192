import { 
  createMain,
  createHackathon,
  list,
  showById,
  confirm,
  revise,
  editMain,
  editHackathon,
  getUnreadCount,
  updateColumnStatus,
  getHackathonStartDate,
  hackathonProject,
  excelExport,
  sendSms
} from '@/api/applications';

export const actions = {
  excelExport({ commit },params) {
    return excelExport(params);
  },
  createMain({ commit },data) {
    return createMain(data);
  },
  editMain({ commit },data) {
    return editMain(data);
  },
  createHackathon({ commit },data) {
    return createHackathon(data);
  },
  editHackathon({ commit },data) {
    return editHackathon(data);
  },
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  showById({ commit },id) {
    return showById(id);
  },
  confirm({ commit }, id) {
    return confirm(id);
  },
  revise({ commit }, data) {
    return revise(data);
  },
  updateColumnStatus({ commit }, data) {
    return updateColumnStatus(data);
  },
  getUnreadCount({ commit }) {
    return new Promise((resolve, reject) => {
      getUnreadCount()
        .then(res => {
          if (res['success']) {
            commit('SET_UNREAD_COUNT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  hackathonProject({ commit }, data) {
    return hackathonProject(data);
  },
  getHackathonStartDate({ commit }) {
    return new Promise((resolve, reject) => {
      getHackathonStartDate()
        .then(res => {
          if (res['success']) {
            commit('SET_START_DATE', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendSms({ commit }, params) {
    return sendSms(params);
  },
};
