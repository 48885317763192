import ru from './ru'
import uz from './uz'
import oz from './oz'
import en from './en'

export default {
  ru,
  uz,
  oz,
  en
}