import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/gallery',
    method: 'get',
    params
  });
}

export function create(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/gallery',
    method: 'post',
    data: formData
  });
}

export function destroy(id) {
  return request({
    url: '/gallery/'+id,
    method: 'delete'
  });
}

export function storeVideo(data) {
  return request({
    url: '/gallery/videos',
    method: 'post',
    data
  });
}

export function videos(params) {
  return request({
    url: '/gallery/videos',
    method: 'get',
    params
  });
}

export function all(params) {
  return request({
    url: '/gallery/all',
    method: 'get',
    params
  });
}