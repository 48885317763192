<template>
  <div id="app">
    <router-view />
    <!-- <img src="/404.png" /> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getToken, checkTokenTime } from "@/utils/auth";
export default {
  data(){
    return {
    }
  },
  created() {
    this.checkAuth();
    this.cacheClear();
    // this.getTasks();
    // this.getRegions();
    // this.getCities();
    // this.getCountries();
    // this.getDirections();
    this.getAll();
    // this.getHackathonStartDate();
    this.getEndVote();
    this.checkRegDatePassed();
    this.checkApplicationEditDatePassed();
    this.checkRegDateHackathonPassed();
  },
  computed: {},
  methods: {
    ...mapActions({
      getCurrentUser: 'auth/currentUser',
      checkRegDatePassed: 'auth/checkRegDatePassed',
      checkApplicationEditDatePassed: 'auth/checkApplicationEditDatePassed',
      checkRegDateHackathonPassed: 'auth/checkRegDateHackathonPassed',
      // getTasks: 'classifiers/getTasks',
      // getRegions: 'classifiers/getRegions',
      // getCities: 'classifiers/getCities',
      // getCountries: 'classifiers/getCountries',
      // getDirections: 'classifiers/getDirections',
      getAll: 'classifiers/getAll',
      getEndVote: 'votation/endVote',
      // getHackathonStartDate: 'applications/getHackathonStartDate',
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('cache deleted')
      }
    },
    checkAuth(){
      let token = getToken();
      if(token && checkTokenTime()){
        this.getCurrentUser();
      }
    }
  }
};
</script>