import { 
  getFirst,
  getList
} from '@/api/notifications';

export const actions = {
  getFirst({ commit }) {
    return getFirst();
  },
  getList({ commit }) {
    return new Promise((resolve, reject) => {
      getList()
        .then(res => {
          if (res['success']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
