import { 
  list,
  create,
  destroy,
  storeVideo,
  videos,
  all
} from '@/api/gallery';

export const actions = {
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return create(data);
  },
  destroy({ commit }, id) {
    return destroy(id);
  },
  storeVideo({ commit }, data) {
    return storeVideo(data);
  },
  getVideos({ commit },params) {
    commit('SET_LIST', null);
    return new Promise((resolve, reject) => {
      videos(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAll({ commit },params) {
    commit('SET_LIST', null);
    return new Promise((resolve, reject) => {
      all(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
