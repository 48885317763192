const routes = [
  {
    path: 'cabinet',
    name: 'VoterCabinet',
    meta: { middleware: 'AuthVoter', title: 'Мой профиль' },
    component: () =>
    import("@/views/voter/Cabinet"),
  },{
    path: 'directions',
    name: 'VoterDirections',
    meta: { middleware: 'AuthVoter', title: 'Голосование' },
    component: () =>
    import("@/views/voter/Directions"),
  },{
    path: 'directions/:id',
    name: 'VoterApplications',
    meta: { middleware: 'AuthVoter', title: 'Голосование' },
    component: () =>
    import("@/views/voter/Applications"),
  },{
    path: 'directions/project/:id',
    name: 'VoterApplicationShow',
    meta: { middleware: 'AuthVoter', title: 'Голосование' },
    component: () =>
    import("@/views/voter/ApplicationShow"),
  }
]

export default routes;
