export const mutations = {
  SET_REGIONS: (state, data) => {
    state.regions = data;
  },
  SET_CATEGORIES: (state, data) => {
    state.categories = data;
  },
  SET_CITIES: (state, data) => {
    state.cities = data;
  },
  SET_TASKS: (state, data) => {
    state.tasks = data;
  },
  SET_COUNTRIES: (state, data) => {
    state.countries = data.concat({
      id: 10001,
      name_en: "A stateless person",
      name_ru: "Лицо без гражданства",
      name_uz: "Fuqaroligi bo'lmagan shaxs",
    });
  },
  SET_DIRECTIONS: (state, data) => {
    state.directions = data;
  },
  SET_ALL: (state, data) => {
    state.cities = data['cities'];
    state.tasks = data['tasks'];
    state.regions = data['regions'];
    state.countries = data['countries'];
    state.directions = data['directions'];
  },
};