import { 
  getDirections,
  getDirection,
  getApplication,
  store,
  list,
  resultByAverageScore,
  resultByAverageScoreHome,
  votesByApplication,
  endVote
} from '@/api/votation';

export const actions = {
  endVote({ commit }) {
    return new Promise((resolve) => {
      endVote()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_END_VOTE', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDirections({ commit }) {
    return getDirections();
  },
  getDirection({ commit },id) {
    return getDirection(id);
  },
  getApplication({ commit },id) {
    return getApplication(id);
  },
  store({ commit },data) {
    return store(data);
  },
  resultByAverageScore({ commit },data) {
    return resultByAverageScore(data);
  },
  resultByAverageScoreHome({ commit },data) {
    return resultByAverageScoreHome(data);
  },
  votesByApplication({ commit },data) {
    commit('SET_LIST', null);
    return new Promise((resolve, reject) => {
      votesByApplication(data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getList({ commit },params) {
    commit('SET_LIST', null);
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};