import request from '@/utils/request';

export function getDirections() {
  return request({
    url: '/votation/directions',
    method: 'get'
  });
}

export function getDirection(id) {
  return request({
    url: '/votation/view-directions/'+id,
    method: 'get'
  });
}

export function getApplication(id) {
  return request({
    url: '/votation/applications/'+id,
    method: 'get'
  });
}

export function store(data) {
  return request({
    url: '/votation',
    method: 'post',
    data
  });
}

export function list(params) {
  return request({
    url: '/votation',
    method: 'get',
    params
  });
}

export function resultByAverageScore(params) {
  return request({
    url: '/votation/result-by-average-score',
    method: 'get',
    params
  });
}

export function resultByAverageScoreHome(params) {
  return request({
    url: '/votation/result-by-average-score-home',
    method: 'get',
    params
  });
}

export function votesByApplication(params) {
  return request({
    url: '/votation/votes-by-application',
    method: 'get',
    params
  });
}

export function endVote() {
  return request({
    url: '/end-vote',
    method: 'get'
  });
}