export const mutations = {
  SET_MAIN_TEAM: (state, data) => {
    state.mainTeam = data;
  },
  SET_HACKATHON_TEAM: (state, data) => {
    state.hackathonTeam = data;
  },
  SET_MY_REQUESTS: (state, data) => {
    state.myRequests = data;
  },
  SET_REMOVAL_REQUESTS: (state, data) => {
    state.removalRequests = data['data'];
    state.removalRequestsPagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_UNREAD_COUNT: (state, data) => {
    state.unreadCount = data;
  },
  SET_LIST: (state, data) => {
    state.list = data['data'];
    state.listPagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
};