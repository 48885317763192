export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data['data'].map(item => {
      item['day_1'] = item['checklists'].find(ch => ch.day == 1);
      item['day_2'] = item['checklists'].find(ch => ch.day == 2);
      item['day_3'] = item['checklists'].find(ch => ch.day == 3);
      return item;
    });
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
};