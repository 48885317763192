import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/users',
    method: 'get',
    params
  });
}

export function create(data) {
  return request({
    url: '/users',
    method: 'post',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/users/'+id,
    method: 'delete'
  });
}