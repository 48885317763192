export const getters = {
  MAIN_TEAM: (state) => state.mainTeam,
  HACKATHON_TEAM: (state) => state.hackathonTeam,
  MY_REQUESTS: (state) => state.myRequests,
  REMOVAL_REQUESTS: (state) => state.removalRequests,
  REMOVAL_REQUESTS_PAGINATION: (state) => state.removalRequestsPagination,
  LIST: (state) => state.list,
  LIST_PAGINATION: (state) => state.listPagination,
  UNREAD_COUNT: (state) => state.unreadCount,
}
