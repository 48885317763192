import { 
  getMembersCountByRegion,
  getMembersCountByGender,
  getMembersCountByAgeAvarage,
  getTeamsCountByRegion,
  getTeamsCountByDirection,
  getMembersRegistrationDailyAvg
} from '@/api/statistics';

export const actions = {
  getMembersCountByRegion({ commit }, params) {
    return getMembersCountByRegion(params);
  },
  getMembersCountByGender({ commit }, params) {
    return getMembersCountByGender(params);
  },
  getMembersCountByAgeAvarage({ commit }, params) {
    return getMembersCountByAgeAvarage(params);
  },
  getTeamsCountByRegion({ commit }, params) {
    return getTeamsCountByRegion(params);
  },
  getTeamsCountByDirection({ commit }, params) {
    return getTeamsCountByDirection(params);
  },
  getMembersRegistrationDailyAvg({ commit }, params) {
    return getMembersRegistrationDailyAvg(params);
  },
};