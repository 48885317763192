import request from '@/utils/request';

export function myTeams() {
  return request({
    url: '/my-teams',
    method: 'get'
  });
}

export function mainTeam() {
  return request({
    url: '/team/main-team',
    method: 'get'
  });
}

export function hackathonTeam() {
  return request({
    url: '/team/hackathon-team',
    method: 'get'
  });
}

export function teamCreate(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/team',
    method: 'post',
    data: formData
  });
}

export function teamEdit(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/team/'+data['id'],
    method: 'post',
    data: formData
  });
}

export function deleteMember(data) {
  return request({
    url: '/team/delete-member',
    method: 'post',
    data
  });
}

export function getTeamByNumber(params) {
  return request({
    url: '/team/search',
    method: 'get',
    params
  });
}

export function sendinvites(data) {
  return request({
    url: '/team/sendinvite',
    method: 'post',
    data
  });
}

export function createJoinRequest(data) {
  return request({
    url: '/joinrequest',
    method: 'post',
    data
  });
}

export function acceptJoiningRequest(id) {
  return request({
    url: '/team/accept-joining-request/'+id,
    method: 'post'
  });
}

export function rejectJoiningRequest(id) {
  return request({
    url: '/team/reject-joining-request/'+id,
    method: 'post'
  });
}

export function leftTeam(id) {
  return request({
    url: '/team/left-team/'+id,
    method: 'post'
  });
}

export function myRequests(params) {
  return request({
    url: '/team/my-requests',
    method: 'get',
    params
  });
}

export function deleteTeam(id) {
  return request({
    url: '/team/'+id,
    method: 'delete'
  });
}

export function storeRemovalRequests(data) {
  return request({
    url: '/team-removal-requests',
    method: 'post',
    data
  });
}

export function listRemovalRequests(params) {
  return request({
    url: '/team-removal-requests',
    method: 'get',
    params
  });
}

export function removalRequestsGetById(id) {
  return request({
    url: '/team-removal-requests/'+id,
    method: 'get'
  });
}

export function removalRequestsConfirm(id) {
  return request({
    url: '/team-removal-requests/confirm/'+id,
    method: 'put'
  });
}

export function removalRequestsReject(data) {
  return request({
    url: '/team-removal-requests/reject/'+data['id'],
    method: 'put',
    data
  });
}

export function getUnreadCount() {
  return request({
    url: '/statistics/count/team-removal-requests',
    method: 'get'
  });
}

export function list(params) {
  return request({
    url: '/team',
    method: 'get',
    params
  });
}

export function show(id) {
  return request({
    url: '/team/'+id,
    method: 'get'
  });
}