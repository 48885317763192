import request from '@/utils/request';

export function store(data) {
  return request({
    url: '/team-change-requests',
    method: 'post',
    data
  });
}

export function byTeamId(id) {
  return request({
    url: '/team-change-requests/by-team-id/'+id,
    method: 'get'
  });
}

export function list(params) {
  return request({
    url: '/team-change-requests',
    method: 'get',
    params
  });
}

export function show(id) {
  return request({
    url: '/team-change-requests/show/'+id,
    method: 'get'
  });
}

export function confirm(id) {
  return request({
    url: '/team-change-requests/confirm/'+id,
    method: 'put'
  });
}

export function reject(data) {
  return request({
    url: '/team-change-requests/reject/'+data['id'],
    method: 'put',
    data
  });
}