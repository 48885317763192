<template>
  <span v-if="model">{{model|t}}</span>
</template>

<script>
export default {
  props: {
    id: {
      type: Number | String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {    
    return {
    }
  },
  computed:{
    model(){
      return this.items.find(item => item.id == this.id);
    }
  },
  methods: {
  }
}
</script>