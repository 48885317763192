import { 
  store,
  list,
} from '@/api/checklist';

export const actions = {
  store({ commit },data) {
    return store(data);
  },
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};