import request from '@/utils/request';

export function homePage() {
  return request({
    url: '/news/home-page',
    method: 'get'
  });
}

export function list(params) {
  return request({
    url: '/news',
    method: 'get',
    params
  });
}

export function show(id) {
  return request({
    url: '/news/'+id,
    method: 'get'
  });
}

export function create(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/news',
    method: 'post',
    data: formData
  });
}

export function edit(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      if(data[key]){
        formData.append(key, data[key]);
      }
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/news/'+data['id'],
    method: 'post',
    data: formData
  });
}

export function destroy(id) {
  return request({
    url: '/news/'+id,
    method: 'delete'
  });
}