export const state = {
  mainTeam: null,
  hackathonTeam: null,
  myRequests: [],
  removalRequests: [],
  removalRequestsPagination: null,
  unreadCount: 0,
  list: [],
  listPagination: null,
}
