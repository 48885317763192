import request from '@/utils/request';

export function memberCreate(data) {
  return request({
    url: '/member',
    method: 'post',
    data
  });
}

export function sendphone(data) {
  return request({
    url: '/member/sendphone',
    method: 'post',
    data
  });
}

export function verifyphone(data) {
  return request({
    url: '/member/verifyphone',
    method: 'post',
    data
  });
}

export function sendEmail(data) {
  return request({
    url: '/send-email',
    method: 'post',
    data
  });
}

export function list(params) {
  return request({
    url: '/members',
    method: 'get',
    params
  });
}

export function showById(id) {
  return request({
    url: '/members/'+id,
    method: 'get'
  });
}

export function confirm(id) {
  return request({
    url: '/members/'+id+'/confirm',
    method: 'put'
  });
}

export function revise(data) {
  return request({
    url: '/members/'+data['id']+'/revise',
    method: 'put',
    data
  });
}

export function sendPending() {
  return request({
    url: '/member/send-pending',
    method: 'put'
  });
}

export function editForeign(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      if(data[key]){
        formData.append(key, data[key]);
      }
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/member/edit-foreign',
    method: 'post',
    data: formData
  });
}

export function editLocal(params) {
  var data = {};
  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      if(params[key].length){
        data[key] = params[key];
      }
    }else{
      if(params[key]){
        data[key] = params[key];
      }
    }
  });
  return request({
    url: '/member/edit-local',
    method: 'put',
    data
  });
}

export function getUnreadCount() {
  return request({
    url: '/statistics/count/members',
    method: 'get'
  });
}

export function downloadList(params) {
  return request({
    url: '/members/excel-export',
    method: 'get',
    params
  });
}

export function sendSms(params) {
  return request({
    url: '/members/send-sms',
    method: 'post',
    params
  });
}

export function getHackathonCertificate(uuid) {
  return request({
    url: '/hackathon-certificate/'+uuid,
    method: 'get'
  });
}