import { 
  store,
  byTeamId,
  list,
  show,
  confirm,
  reject
} from '@/api/teamChangeRequests';

export const actions = {
  store({ commit },data) {
    return store(data);
  },
  byTeamId({ commit },id) {
    commit('SET_BY_TEAM_ID', []);
    return new Promise((resolve, reject) => {
      byTeamId(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_BY_TEAM_ID', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit },id) {
    return show(id);
  },
  confirm({ commit },id) {
    return confirm(id);
  },
  reject({ commit },data) {
    return reject(data);
  },
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};