import request from '@/utils/request';

export function getMembersCountByRegion(params) {
  return request({
    url: '/statistics/members/count-by-region',
    method: 'get',
    params
  });
}

export function getMembersCountByGender(params) {
  return request({
    url: '/statistics/members/count-by-gender',
    method: 'get',
    params
  });
}

export function getMembersCountByAgeAvarage(params) {
  return request({
    url: '/statistics/members/age-avarage',
    method: 'get',
    params
  });
}

export function getTeamsCountByRegion(params) {
  return request({
    url: '/statistics/teams/count-by-region',
    method: 'get',
    params
  });
}

export function getTeamsCountByDirection(params) {
  return request({
    url: '/statistics/teams/count-by-direction',
    method: 'get',
    params
  });
}

export function getMembersRegistrationDailyAvg(params) {
  return request({
    url: '/statistics/members/registration-daily-avg',
    method: 'get',
    params
  });
}