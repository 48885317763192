import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewares from "./middlewares";
import store from "@/store/";

import admin from './modules/admin'
import member from './modules/member'
import voter from './modules/voter'

import {checkTokenTime, clearForLogout} from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "SelectPage",
    component: () =>
      import("@/views/SelectPage"),
  },
  {
    path: "/president-tech-award",
    name: "Main",
    redirect: { name: "Home" },
    component: () =>
      import("@/layouts/Landing"),
    children: [{
      path: '/',
      name: 'Home',
      meta: {savePosition:true},
      component: () =>
        import("@/views/HomeView"),
    }]
  },
  // layouts/Empty
  {
    path: '/waitlist/form',
    name: 'WaitlistForm',
    component: () =>
      import("@/views/WaitlistForm"),
  },
  {
    path: "/",
    name: "Empty",
    component: () =>
      import("@/layouts/Empty"),
    children: [{
      path: 'news/:id/show',
      name: 'NewsShow',
      component: () =>
        import("@/views/news/Show"),
    },{
      path: 'login',
      name: 'Login',
      component: () =>
        import("@/views/auth/Login"),
    },{
      path: 'auth-one-id',
      name: 'LoginOneId',
      component: () =>
        import("@/views/auth/LoginOneId"),
    },{
      path: 'auth-face-id',
      name: 'LoginFaceId',
      component: () =>
        import("@/views/auth/LoginFaceId"),
    },{
      path: 'local-register',
      name: 'LocalRegister',
      meta: { middleware: 'AuthApplicator' },
      component: () =>
      import("@/views/auth/register/LocalRegister"),
    },{
      path: 'foreign-register',
      name: 'ForeignRegister',
      component: () =>
      import("@/views/auth/register/ForeignRegister"),
    },{
      path: 'foreign-login',
      name: 'ForeignLogin',
      component: () =>
      import("@/views/auth/ForeignLogin"),
    },{
      path: 'forgot-password',
      name: 'ForgotPassword',
      component: () =>
      import("@/views/auth/ForgotPassword"),
    },{
      path: 'reset-password',
      name: 'ResetPassword',
      component: () =>
      import("@/views/auth/ResetPassword"),
    },{
      path: 'admin-login',
      name: 'AdminLogin',
      component: () =>
      import("@/views/auth/AdminLogin"),
    },{
      path: 'votation-results/:id',
      name: 'VotationResults',
      component: () =>
        import("@/views/VotationResults"),
    },{
      path: 'gallery',
      name: 'Gallery',
      component: () =>
        import("@/views/gallery/Index"),
    },{
      path: 'hackathon-certificate/:uuid',
      name: 'HackathonCertificate',
      component: () =>
        import("@/views/certificates/Hackathon"),
    },{
      path: 'directions/applications/:id',
      name: 'DirectionApplications',
      component: () =>
      import("@/views/voter/Applications"),
    },{
      path: 'directions/applications/project/:id',
      name: 'DirectionApplicationsProject',
      component: () =>
      import("@/views/voter/ApplicationShow"),
    }]
  },
  // Member
  {
    path: "/",
    name: "Cabinet",
    component: () =>
      import("@/layouts/Cabinet"),
    children: member
  },
  // voter
  {
    path: "/voter",
    name: "Voter",
    component: () =>
      import("@/layouts/Cabinet"),
    children: voter
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import("@/views/Test"),
  },
  // Admin
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import("@/layouts/Admin"),
    children: admin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(!to.meta['savePosition']){
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.middleware) {
    let $res = checkMiddleware(to.meta.middleware);
    if (!$res || !checkTokenTime()) {
      clearForLogout();
      return next({ name: "Login", query: {redirect_url: to.path} });
    }
  }
  return next();
});

function checkMiddleware(middleware) {
  var middlewareSplit = middleware.split("|");
  var result = false;
  middlewareSplit.forEach(middlewareItem => {
    if (middlewares[middlewareItem]) {
      let $res = middlewares[middlewareItem](store);
      if($res) result = true;
    }
  });
  return result;
}

export default router
