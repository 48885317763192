import Auth from './Auth'
import AuthAdmin from './AuthAdmin'
import AuthApplicator from './AuthApplicator'
import AuthRedactor from './AuthRedactor'
import AuthRegistration from './AuthRegistration'
import AuthVoter from './AuthVoter'
import AuthJury from './AuthJury'
import AuthHackathon from './AuthHackathon'
import AuthJuryHackathon from './AuthJuryHackathon'

export default {
  Auth,
  AuthAdmin,
  AuthApplicator,
  AuthRedactor,
  AuthRegistration,
  AuthVoter,
  AuthJury,
  AuthHackathon,
  AuthJuryHackathon
}