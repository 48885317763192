import { mapGetters, mapActions } from 'vuex'
import { 
    cloneObject as utilsCloneObject, 
    orderBy as utilsOrderBy 
} from '@/utils/index'
export default {
    data() {
        return {
            screenWidth: 1400,
        }
    },
    computed: {
        ...mapGetters({
            isAuth: 'auth/IS_AUTH',
            currentUser: 'auth/USER',
            loadRunningGetter: 'loader/REQUEST_RUNNING',
            hackathonStartDate: 'applications/START_DATE',
            prevRoute: 'app/PREV_ROUTE',
            endVote: 'votation/END_VOTE',
            regDatePassed: 'auth/REG_DATE_PASSED',
            applicationEditDatePassed: 'auth/APPLICATION_EDIT_DATE_PASSED',
            regDateHackathonPassed: 'auth/REG_DATE_HACKATHON_PASSED',
        }),
        loadRunning() {
            return this.loadRunningGetter < 0 ? 0 : this.loadRunningGetter;
        },
        currentMember() {
            return this.currentUser ? this.currentUser['member'] : null
        },
        isForeignUser() {
            return this.currentUser ? this.currentUser['type'] == 2 : false
        },
        isForeignMember() {
            return this.currentMember ? this.currentMember['type'] == 2 : false
        },
        isApplicator() {
            return this.currentUser ? this.currentUser['role'] == 'applicator' : false;
        },
        isVoter() {
            return this.currentUser ? this.currentUser['role'] == 'voter' : false;
        },
        isAdmin() {
            return this.currentUser ? this.currentUser['role'] == 'admin' : false;
        },
        isRedactor() {
            return this.currentUser ? this.currentUser['role'] == 'redactor' : false;
        },
        isRegistration() {
            return this.currentUser ? this.currentUser['role'] == 'registration' : false;
        },
        isJury() {
            return this.currentUser ? this.currentUser['role'] == 'jury' : false;
        },
        isJuryHackathon() {
            return this.currentUser ? this.currentUser['role'] == 'jury-hackathon' : false;
        },
        isJuryMain() {
            return this.currentUser ? this.currentUser['role'] == 'jury-main' : false;
        },
        isUserHackathon() {
            return this.currentUser ? this.currentUser['role'] == 'hackathon' : false;
        },
        isDev() {
            return process.env.NODE_ENV == "development";
        },
        isActiveMember(){
            return this.currentMember?this.currentMember.status == 1:false;
        },
        // endVote(){
        //     var endDate = this.$moment('2023-12-11 13:00:00');
        //     return endDate.diff(this.$moment().clone(), 'seconds');
        // }
        // isHackathonDay(){
        //     if(this.hackathonStartDate){
        //         var currentDate = this.$moment(this.$moment().format('YYYY-MM-DD'));
        //         var currentDateDiff = currentDate.diff(this.$moment(this.hackathonStartDate), 'days');
        //         return currentDateDiff < 3 && currentDateDiff >= 0;
        //     }
        //     return false;
        // }
    },
    created(){
        this.screenWidth = window.innerWidth; 
        window.addEventListener('resize', this.resizeScreen);
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeScreen);
    },
    methods: {
        ...mapActions({
            setPrevRoute: 'app/setPrevRoute',
        }),
        resizeScreen(){
            this.screenWidth = window.innerWidth;
        },
        cloneObject(object) {
            return utilsCloneObject(object);
        },
        orderBy(data, field, type = 'asc', byLength = false) {
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                delete query[key];
                this.$router.push({ name: this.$route.name, params: this.$route.params, query });
            }
        },
        openFile(base64, filename) {
            let link = document.createElement("a");
            link.href = base64;
            link.download = filename;
            // link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        fullPath(path) {
            return process.env.VUE_APP_PUBLIC + path;
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                if (query[key]) {
                    delete query[key];
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            }
        },
        getMonthName(date){
            const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            var result = new Date(date);
            return monthNames[result.getMonth()];
        },
        getDay(date){
            var result = new Date(date);
            return result.getDate();
        },
        getYear(date){
            var result = new Date(date);
            return result.getFullYear();
        },
        getYearRu(n){
            let str1 = this.$t('год');
            let str2 = this.$t('года');
            let str5 = this.$t('лет');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getAge(n){
            let str1 = this.$t('год в');
            let str2 = this.$t('года в');
            let str5 = this.$t('лет в');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getMonthRu(n){
            let str1 = this.$t('месяц');
            let str2 = this.$t('месяца');
            let str5 = this.$t('месяцев');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        even(x){
            if(Number.isInteger(x) == true){return x % 2 == 0}else{return undefined}
        },
        getIsHackathonDay(){
            if(this.hackathonStartDate){
                var currentDate = this.$moment(this.$moment().format('YYYY-MM-DD'));
                var currentDateDiff = currentDate.diff(this.$moment(this.hackathonStartDate), 'days');
                return currentDateDiff < 7 && currentDateDiff >= 0;
            }
            return false;
        },
        getTotalBalls(scores){
          var total = 0;
          if(scores && scores.length){
            scores.forEach(score => {
                if(score.calc_percent){
                    total += (score.ball*score.calc_percent);
                }else{
                    total += score.ball;
                }
            });
          }
          return total.toFixed(2);
        },
        getRoleT(role){
            switch (role) {
                case 'jury':
                    return this.$t('Жюри неотобранные');
                case 'jury-main':
                    return this.$t('Жюри отобранные');
                default:
                    return this.$t(role);
            }
        }
    }
};