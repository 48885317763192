import { 
  regions, 
  categories, 
  cities,
  tasks,
  countries,
  directions,
  getAll
} from '@/api/classifiers';

export const actions = {
  getRegions({ commit },params) {
    return new Promise((resolve, reject) => {
      regions(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REGIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCities({ commit },params) {
    return new Promise((resolve, reject) => {
      cities(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CITIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTasks({ commit },params) {
    return new Promise((resolve, reject) => {
      tasks(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_TASKS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCategories({ commit },params) {
    return new Promise((resolve, reject) => {
      categories(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CATEGORIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCountries({ commit },params) {
    return new Promise((resolve, reject) => {
      countries(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COUNTRIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDirections({ commit },params) {
    return new Promise((resolve, reject) => {
      directions(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_DIRECTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAll({ commit },params) {
    return new Promise((resolve, reject) => {
      getAll(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_ALL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
