import { 
  list,
  store,
  show,
  byStatus,
  unratedApplications,
  byApplication,
  resultsByApplicationId,
  finals
} from '@/api/juryVotation';

export const actions = {
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUnratedApplications({ commit },params) {
    return new Promise((resolve, reject) => {
      unratedApplications(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_APPLICATIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getByApplication({ commit }, id) {
    return byApplication(id);
  },
  getResultsByApplicationId({ commit }, id) {
    return resultsByApplicationId(id);
  },
  getByStatus({ commit }, params) {
    return byStatus(params);
  },
  store({ commit }, data) {
    return store(data);
  },
  show({ commit }, application_id) {
    return show(application_id);
  },
  finals({ commit }, params) {
    return finals(params);
  }
};