import { 
  teamCreate,
  myTeams,
  mainTeam,
  hackathonTeam,
  deleteMember,
  getTeamByNumber,
  sendinvites,
  createJoinRequest,
  acceptJoiningRequest,
  rejectJoiningRequest,
  leftTeam,
  myRequests,
  teamEdit,
  deleteTeam,
  storeRemovalRequests,
  listRemovalRequests,
  removalRequestsGetById,
  removalRequestsConfirm,
  removalRequestsReject,
  getUnreadCount,
  list,
  show
} from '@/api/teams';

export const actions = {
  getMainTeam({ commit }) {
    return new Promise((resolve, reject) => {
      mainTeam()
        .then(res => {
          if (res['success']) {
            commit('SET_MAIN_TEAM', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getHackathonTeam({ commit }) {
    return new Promise((resolve, reject) => {
      hackathonTeam()
        .then(res => {
          if (res['success']) {
            commit('SET_HACKATHON_TEAM', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  teamCreate({ commit }, data) {
    return teamCreate(data);
  },
  teamEdit({ commit }, data) {
    return teamEdit(data);
  },
  deleteMember({ commit }, data) {
    return deleteMember(data);
  },
  getTeamByNumber({ commit }, params) {
    return getTeamByNumber(params);
  },
  sendinvites({ commit }, data) {
    return sendinvites(data);
  },
  createJoinRequest({ commit }, data) {
    return createJoinRequest(data);
  },
  acceptJoiningRequest({ commit }, id) {
    return acceptJoiningRequest(id);
  },
  rejectJoiningRequest({ commit }, id) {
    return rejectJoiningRequest(id);
  },
  leftTeam({ commit }, id) {
    return leftTeam(id);
  },
  myRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      myRequests(params)
        .then(res => {
          if (res['success']) {
            commit('SET_MY_REQUESTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteTeam({ commit }, id) {
    return deleteTeam(id);
  },
  storeRemovalRequests({ commit }, data) {
    return storeRemovalRequests(data);
  },
  listRemovalRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      listRemovalRequests(params)
        .then(res => {
          if (res['success']) {
            commit('SET_REMOVAL_REQUESTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removalRequestsGetById({ commit }, id) {
    return removalRequestsGetById(id);
  },
  removalRequestsConfirm({ commit }, data) {
    return removalRequestsConfirm(data);
  },
  removalRequestsReject({ commit }, data) {
    return removalRequestsReject(data);
  },
  getUnreadCount({ commit }) {
    return new Promise((resolve, reject) => {
      getUnreadCount()
        .then(res => {
          if (res['success']) {
            commit('SET_UNREAD_COUNT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  showById({ commit }, id) {
    return show(id);
  },
};